const pozner = {
  categoryName: 'pozner',
  randomOrder: true,
  introText: 'Здравствуйте! У моего хорошего друга Марселя Пруста есть к Вам несколько вопросов.',
  questions: [
    { question: 'Оказавшись перед Богом, что Вы ему скажете?' },
    {
      question: 'Если бы Вы могли пообщаться с любым из когда-либо живших людей, кто бы это был?',
    },
    { question: 'Как Вы относитесь к богатству?' },
    {
      question: 'Если бы Вы могли купить одну единственную вещь, что бы это было?',
    },
    { question: 'Кто был Вашим любимым героем детства?' },
    { question: 'Счастье - это?' },
    { question: 'Несчастье - это?' },
    { question: 'Какой грех Вы прощаете проще всего?' },
    { question: 'Какой грех Вы никогда не простите?' },
    {
      question: 'Назовите три слова которые Вы хотели чтобы говорили о вас?',
    },
    { question: 'Вы поймали золотую рыбку. Ваши три желания?' },
    { question: 'Чего Вы боитесь больше всего?' },
    { question: 'В каких случаях Вы лжете?' },
    {
      question: 'Какие три книжки Вы взяли бы с собой на необитаемый остров?',
    },
    { question: 'Карьера или принципы?' },
    { question: 'У Вас есть кумир? Кто он?' },
    { question: 'Какого таланта у Вас нет, о котором Вы сожалеете?' },
    {
      question: 'По двадцатибальной шкале какую оценку Вы бы себе дали?',
    },
    { question: 'Что бы Вы изменили в себе, если бы могли? ' },
    { question: 'Какие добродетели Вы цените больше всего?' },
    { question: 'Если не собой, то кем Вам хотелось бы быть?' },
    { question: 'Кто Ваши любимые литературные персонажи?' },
    { question: 'Ваши любимые герои в реальной жизни?' },
    { question: 'Ваши любимые героини в реальной жизни?' },
    { question: 'Ваши любимые литературные женские персонажи?' },
    { question: 'Ваше любимое блюдо, напиток?' },
    { question: 'Ваши любимые имена?' },
    { question: 'К чему Вы испытываете отвращение?' },
    {
      question: 'Какие исторические личности вызывают Вашу наибольшую антипатию?',
    },
    { question: 'Ваше любимое изречение?' },
    { question: 'Ваша самая характерная черта?' },
    {
      question: 'Качества, которые Вы больше всего цените в мужчине?',
    },
    {
      question: 'Качества, которые Вы больше всего цените в женщине?',
    },
    { question: 'Что Вы больше всего цените в Ваших друзьях?' },
    { question: 'Что является Вашим главным недостатком?' },
    { question: 'Какое Ваше любимое занятие?' },
    { question: 'Каким Вы хотели бы быть?' },
    { question: 'В какой стране Вам хотелось бы жить?' },
    { question: 'Ваш любимый цвет?' },
    { question: 'Ваш любимый цветок?' },
    { question: 'Ваша любимая птица?' },
    { question: 'Ваши любимые писатели?' },
    { question: 'Ваши любимые поэты?' },
    { question: 'Ваши любимые композиторы?' },
    { question: 'Ваши любимые художники?' },
    { question: 'Ваша любимая героиня в истории?' },
    { question: 'Что Вы больше всего ненавидите?' },
    { question: 'Исторические персонажи, которых Вы презираете?' },
    {
      question: 'Какой момент в военной истории Вы цените больше всего?',
    },
    { question: 'Реформа, которую Вы цените особенно высоко?' },
    { question: 'Способность, которой Вам хотелось бы обладать?' },
    { question: 'Как Вы хотели бы умереть?' },
    { question: 'Ваше состояние духа в настоящий момент?' },
    { question: 'Ваш девиз?' },
  ],
}

const dud = {
  categoryName: 'dud',
  randomOrder: true,
  introText: 'Всем привет! К этому интервью мы готовились ровно год. И вот наконец-то мы здесь. Фух, вперед!',
  questions: [
    { category: 'dud', question: 'Сколько ты зарабатываешь?' },
    { category: 'dud', question: 'Оказавшишь перед Путиным, что ты ему скажешь?' },
    { category: 'dud', question: 'В чем сила?' },
    { category: 'dud', question: 'Что будет с Россией через три года?' },
    { category: 'dud', question: 'Что тебя не устраивает в России?' },
    { category: 'dud', question: 'Телевизор - это зло?' },
    { category: 'dud', question: 'Ты обсуждаешь политику с близкими?' },
    { category: 'dud', question: '"Что Было Дальше" - это круто?' },
    { category: 'dud', question: 'Три лучших актера прямо сейчас?' },
    { category: 'dud', question: 'Три лучших актрисы прямо сейчас?' },
    { category: 'dud', question: 'Три лучших фильма за всю историю?' },
    { category: 'dud', question: 'Три лучших группы за всю историю?' },
    { category: 'dud', question: 'Три твоих лучших музыкальных открытия за последнее время?' },
    { category: 'dud', question: 'Почему Россия бухает?' },
    { category: 'dud', question: 'Ты веришь в Бога?' },
    { category: 'dud', question: 'Путин - красавчик?' },
    { category: 'dud', question: 'Деньги или честь?' },
    { category: 'dud', question: 'Деньги или принципы?' },
    { category: 'dud', question: 'У тебя есть объяснение почему народ фанатеет от Сталина?' },
    { category: 'dud', question: 'Можно ли критиковать Россию, когда живешь в Европе?' },
    { category: 'dud', question: 'В чем смысл жизни?' },
    { category: 'dud', question: 'Москва - это Европа или Азия?' },
  ],
}

const sparkFunEn = {
  categoryName: 'spark-fun-en',
  randomOrder: true,
  introText:
    "Hi! If you have interesting questions to ask someone, you also get them interested in you and what you have to say. Let's try!",
  questions: [
    {
      question:
        'Would you rather be in a real-life version of the walking dead or a real-life version of game of thrones?',
    },
    {
      question:
        'Would you rather have batman’s skills, money, equipment, and lifestyle or end crime around the world for good but be poor and unnoticed?',
    },
    { question: 'How long would you last in a zombie apocalypse?' },
    { question: 'What “old person” things do you do?' },
    { question: 'What did you think was cool then, when you were young but isn’t cool now?' },
    {
      question: 'If you could know the absolute and total truth to one question, what question would you ask?',
    },
    { question: 'What is the most unprofessional thing you’ve seen someone do?' },
    { question: 'You are about to get into a fight, what song comes on as your soundtrack?' },
    { question: 'What is something that most people learn only after it’s too late?' },
    { question: 'What is the best way the 1% could spend their money?' },
    {
      question: 'How many friends do you have on facebook and how many actually mean something to you?',
    },
    { question: 'Which of your personality traits has been the most useful?' },
    { question: 'How important are strong family ties to you?' },
    { question: 'If you had a theme song, what would it be?' },
    { question: 'If mars was livable, would you accept a one-way ticket there?' },
    { question: 'What’s a movie you can practically quote from start to finish?' },
    {
      question: 'If you could write one new law that everyone had to obey, what law would you create?',
    },
    { question: 'What do you like more, being a leader or a follower?' },
    {
      question:
        'What’s one pet peeve of yours that you wish that you could get rid of–because it hampers your enjoyment of life?',
    },
  ],
}

const firstDate = {
  categoryName: 'first-date',
  randomOrder: true,
  introText: 'Потренируемся в ответах на вопросы, которые могут быть заданы на первом свидании.',
  questions: [
    {
      question: 'Каким ты представляешь себе идеальное первое свидание?',
    },
    {
      question: 'Какой, по-твоему, самый романтичный город?',
    },
    {
      question: 'Ты считаешь себя романтиком?',
    },
    {
      question: 'Какая погода тебе кажется наиболее романтичной?',
    },
    {
      question: 'Ты веришь в дружбу между мужчиной и женщиной?',
    },
    {
      question: 'С каким заблуждением о себе ты сталкиваешься чаще всего?',
    },
    {
      question: 'Какой работой ты никогда не cмогла/смог бы заниматься?',
    },
    {
      question: 'Как ты встретил своего лучшего друга?',
    },
    {
      question: 'Есть что-то, что многие боятся делать, а ты нет?',
    },
    {
      question: 'Какое у тебя самое яркое воспоминание из детства?',
    },
    {
      question: 'Кого ты любишь больше, кошек или собак?',
    },
    {
      question: 'Ты жаворонок или сова?',
    },
    {
      question: 'Кто оказал наибольшее влияние на твою жизнь?',
    },
    {
      question: 'Какая у тебя цель в жизни прямо сейчас?',
    },
    {
      question: 'Какие сериалы тебе нравятся?',
    },
    {
      question: 'Ты любишь путешествовать? Расскажи про свое самое запоминающееся путешествие.',
    },
    {
      question: 'Как ты отдыхаешь после тяжелых рабочих дней?',
    },
    {
      question: 'Какой по-твоему мнению должна быть роль женщины или мужчины в отношениях?',
    },
  ],
}

const aboutPartner = {
  categoryName: 'about-partner',
  randomOrder: true,
  introText: 'Вопросы о Вашем партнере, которые помогут Вам лучше понять Ваши отношения.',
  questions: [
    {
      question: 'Какую часть своей прошлой жизни Вы чаще обсуждаете со своим партнером?',
    },
    {
      question: 'Что сводит Вас с ума в Вашем партнере?',
    },
    {
      question: 'Какой лучший подарок Вы получали от партнера?',
    },
    {
      question: 'Вы полностью доверяете своему партнеру?',
    },
    {
      question: 'Что бы Вы сказали своему бывшему партнеру, если бы случайно встретили его?',
    },
    {
      question: 'Что бы Вы хотели сделать со своим любимым человеком, чего Вы никогда не делали?',
    },
    {
      question: 'Когда Ваши отношения были совершенно счастливыми?',
    },
    {
      question: 'Вы можете быть счастливымы только оттого, что счастлив Ваш партнер?',
    },
    {
      question: 'Кто в Ваших отношениях доминирует?',
    },
    {
      question: 'На какие уступки Вы идете, чтобы сохранить отношения с любимым человеком?',
    },
    {
      question: 'Два дня без партнера: как бы Вы хотели их провести?',
    },
    {
      question: 'Вы согласитесь, если Ваш партнер предложит Вам что-то необычное?',
    },
    {
      question: 'Кто в Ваших отношениях дизайнер интерьера?',
    },
    {
      question: 'Каким был Ваш первый год совместной жизни?',
    },
    {
      question: 'Вы любите гулять с партнером, держась за руки?',
    },
    {
      question: 'Какие приятные сюрпризы делал Ваш партнер?',
    },
    {
      question: 'Какие неприятные сюрпризы делал Ваш партнер?',
    },
    {
      question: 'Когда и как Вы поняли, что по-настоящему любите своего партнера?',
    },
    {
      question: 'Как сильно Ваш партнер влияет на Вас?',
    },
    {
      question: 'Кто в Вашей семье больше зарабатывает и как это сказывается на Ваших отношениях?',
    },
    {
      question: 'Кто оплачивает походы в рестораны, кино и т. д. в Вашей паре?',
    },
    {
      question: 'Вы со своим партнером – из одной социальной среды?',
    },
    {
      question: 'Чего бы Вам хотелось, чтобы Ваш партнер делал почаще?',
    },
    {
      question: 'Как часто Вы устраиваете романтические вечера своему партнеру?',
    },
    {
      question: 'Вы со своим партнером были у семейного психолога? Считаете ли Вы это полезной практикой?',
    },
    {
      question: 'Как, по-Вашему, двое становятся единым целым?',
    },
    {
      question: 'Что может стать причиной для разрыва отношений?',
    },
    {
      question: 'Какой подарок от твоей второй половинки очень ценен для Вас?',
    },
    {
      question: 'Что нового Вы узнали о своем партнере за последние шесть месяцев?',
    },
    {
      question: 'Кто из Вас оплачивает отпуск?',
    },
    {
      question: 'Опишите своего идеального партнера.',
    },
    {
      question: 'Что Вы до сих пор скрываете от своего партнера?',
    },
    {
      question: 'Оправдались ли Ваши ожидания, касающиеся отношений с партнером?',
    },
    {
      question: 'Что самое плохое/хорошее произошло с Вашим нынешним возлюбленным?',
    },
    {
      question: 'Кто умнее – Вы или Ваш партнер?',
    },
    {
      question: 'Какие три вещи Вы любишь делать со своим партнером?',
    },
    {
      question: 'Кто в Ваших отношениях чаще принимает решение?',
    },
    {
      question: 'Какое первое впечатление произвел на Вас Ваш партнер?',
    },
  ],
}

const aboutLove = {
  categoryName: 'about-love',
  randomOrder: true,
  introText: 'Поговорим на тему самого прекрасного чувства.',
  questions: [
    {
      question: 'Как часто Вы влюбляетесь?',
    },
    {
      question: 'Вы верите в любовь с первого взгляда?',
    },
    {
      question: 'Про кого Вы можете сказать, что он или она – «любовь всей моей жизни»?',
    },
    {
      question: 'Какой Вы видите свою свадьбу?',
    },
    {
      question: 'Как часто Вы влюбляетесь?',
    },
    {
      question: 'Ваша любимая песня о любви?',
    },
    {
      question: 'Ваш любимый фильм о любви?',
    },
    {
      question: 'Что героического Вы сделали ради любви?',
    },
    {
      question: 'Кто был Вашей самой трудной любовной победой? Самой легкой?',
    },
    {
      question: 'Вас не смущают поцелуи на людях?',
    },
    {
      question: 'Какие знаки зодиака Вам больше всего подходят в качестве партнеров?',
    },
    {
      question: 'Вы когда-нибудь отбивали девушку/парня у своего друга/подруги?',
    },
    {
      question: 'Вы ревнивы?',
    },
    {
      question: 'Вы общаетесь со своими бывшими возлюбленными?',
    },
    {
      question: 'Вы когда-нибудь влюблялись более чем в одного человека?',
    },
    {
      question: 'Какой безумный поступок Вы совершали ради любви?',
    },
    {
      question: 'Вам нравится флиртовать?',
    },
    {
      question: 'Как бы Вы хотели, чтобы Ваш партнер проявлял свою любовь?',
    },
    {
      question: 'Вы знаете, как сложилась жизнь у Вашей первой любви?',
    },
    {
      question: 'Что такое любовь?',
    },
    {
      question: 'Почему люди пытаются изменить друг друга?',
    },
    {
      question: 'Опишите Ваши ощущения, когда Вы в первый раз влюбились?',
    },
    {
      question: 'Куда Вы пойдете знакомиться с людьми, если Вы будете одиноки?',
    },
    {
      question: 'Какие слова Вы бы хотели, чтобы Вам шептали на ухо?',
    },
    {
      question: 'Вы заходите на сайты знакомств?',
    },
    {
      question: 'Что Вас пугает в любовных отношениях?',
    },
    {
      question: 'Были ли Вы влюблены без взаимности?',
    },
    {
      question: 'Вы – фаталист?',
    },
    {
      question: 'Как Вы флиртуете и привлекаете к себе внимание?',
    },
    {
      question: 'Вам свойственно флиртовать, даже если Вы находитесь в обществе своего партнера?',
    },
    {
      question: 'Сколько свободы Вам необходимо в отношениях?',
    },
    {
      question: 'Какое существует самое неверное представление о любви, по Вашему мнению?',
    },
    {
      question: 'Если бы Вы любили кого-то, а он Вам предложил только дружбу, Вы бы согласились?',
    },
    {
      question: 'Как часто Вы говорите «я люблю тебя»?',
    },
    {
      question: 'Какие самые крепкие отношения были в Вашей жизни?',
    },
    {
      question: 'Каких сюрпризов Вы можете ожидать от любящих Вас людей?',
    },
    {
      question: 'Как людям следует выражать Вам их любовь?',
    },
    {
      question: 'За кем Вы готовы следовать долгое-долгое время?',
    },
    {
      question: 'Что хорошего в завязывании новых отношений?',
    },
    {
      question: 'Что плохого в завязывании новых отношений?',
    },
    {
      question: 'Ваше представление об идеальном мужчине/женщине?',
    },
    {
      question: 'Сколько раз в день Вам необходимо обниматься?',
    },
    {
      question: 'Кто, по-Вашему, самая романтическая пара в мировой истории, в книгах, кино?',
    },
    {
      question: 'Вспомни самый тяжелый разрыв, о котором Вы слышали.',
    },
    {
      question: 'Какое лучшее лекарство от любовной тоски?',
    },
    {
      question: 'Какова самая типичная причина разрыва отношений?',
    },
    {
      question: 'Как много времени надо, чтобы исцелить Ваше разбитое сердце?',
    },
    {
      question: 'По-Вашему, любовь освобождает человека или, наоборот, лишает его свободы?',
    },
    {
      question: 'Что зажигает в Вас огонь, а что тушит?',
    },
  ],
}

const aboutMarriage = {
  categoryName: 'about-marriage',
  randomOrder: true,
  introText: 'Самое время обсудить свадебные вопросы.',
  questions: [
    {
      question:
        'Как Вы считаете, велика ли вероятность того, что брак будет удачным, если у обеих сторон этот брак – первый?',
    },
    {
      question: 'Что самое лучшее есть в браке?',
    },
    {
      question:
        'Как Вы считаете, могут ли два человека очень сильно любить друг друга и при этом не иметь желания вступать в брак?',
    },
    {
      question: 'Опишите идеальный брак',
    },
    {
      question: 'Что Вы думаете о свободных браках?',
    },
    {
      question: 'Какова наиболее важная причина для брака?',
    },
    {
      question: 'Что Вы думаете о браке с человеком другой расы, национальности, вероисповедания и т.д.?',
    },
    {
      question: 'Какие иллюзии по поводу брака у Вас были до замужества/женитьбы?',
    },
    {
      question: 'Что Вам нравится в браке в большей, а что в меньшей степени?',
    },
    {
      question: 'Как Вы считаете, может ли быть какая-нибудь другая причина для брака, кроме любви?',
    },
    {
      question: 'Вы бы вступили бы в брак с человеком гораздо старше тебя? Младше?',
    },
    {
      question: 'Что является неотъемлемой составляющей счастливого брака?',
    },
    {
      question: 'Что является самой тяжелой стороной брака?',
    },
    {
      question: 'До какого возраста людям нежелательно вступать в брак?',
    },
    {
      question: 'Вы бы хотели заключить брачный контракт?',
    },
    {
      question: 'Куда бы Вы хотели отправиться в свой медовый месяц?',
    },
    {
      question: 'Опишите свою свадьбу или какой бы Вы хотели ее видеть.',
    },
    {
      question: 'Как Вы думаете, почему сегодня люди так часто разводятся?',
    },
  ],
}

export default {
  pozner: pozner,
  dud: dud,
  'spark-fun-en': sparkFunEn,
  'first-date': firstDate,
  'about-partner': aboutPartner,
  'about-love': aboutLove,
  'about-marriage': aboutMarriage,
}
