import React, { useEffect, useState } from 'react'
import { useSpring, animated } from 'react-spring'
import { Button } from '../../../design'
import { springFadeInConfig } from '../../../utils/animation'
import AnswerPlayer from './AnswerPlayer'
import AnswerRecorder from './AnswerRecorder'
import './ExerciseStep.scss'

const ExerciseStep = ({
  questions,
  mimeType,
  onAddAnswer,
  onNoMoreQuestions,
  onSkipQuestion: onSkipQuestionParent,
  onRemoveLastAnswer,
  onQuestionComplete,
  currentQuestionIndex,
  setCurrentQuestionIndex,
  courseMode = false,
}) => {
  const [isRecordingAnswer, setIsRecordingAnswer] = useState(true)
  const [answerBlobUrl, setAnswerBlobUrl] = useState()
  const springProps = useSpring(springFadeInConfig)

  useEffect(() => {
    return () => {
      if (answerBlobUrl) {
        window.URL.revokeObjectURL(answerBlobUrl)
      }
    }
  }, [])

  const onNextQuestion = () => {
    setCurrentQuestionIndex(prev => prev + 1)
    setAnswerBlobUrl(null)
    setIsRecordingAnswer(true)
    window.URL.revokeObjectURL(answerBlobUrl)
    onQuestionComplete()
  }

  const onSkipQuestion = () => {
    onSkipQuestionParent(questions[currentQuestionIndex])
    setCurrentQuestionIndex(prev => prev + 1)
  }

  const onAnswerAgain = () => {
    onRemoveLastAnswer()
    setAnswerBlobUrl(null)
    setIsRecordingAnswer(true)
    window.URL.revokeObjectURL(answerBlobUrl)
  }

  const onRecordingComplete = (recordedChunks, duration) => {
    const blob = new Blob(recordedChunks, {
      type: mimeType,
    })
    onAddAnswer(questions[currentQuestionIndex], blob, duration)
    const url = URL.createObjectURL(blob)
    setAnswerBlobUrl(url)
    setIsRecordingAnswer(false)
  }

  if (currentQuestionIndex >= questions.length) {
    return (
      <div className="section-exercise__no-more-questions section-inner section-inner--m vertical-indent--xs">
        <animated.div style={springProps}>
          <h1>В данной категории больше нет вопросов</h1>
          <Button size="large" onClick={onNoMoreQuestions}>
            Посмотреть результаты интервью
          </Button>
        </animated.div>
      </div>
    )
  }

  return (
    <>
      {isRecordingAnswer && (
        <AnswerRecorder
          question={questions[currentQuestionIndex]}
          mimeType={mimeType}
          onRecordingComplete={onRecordingComplete}
          onSkipQuestion={onSkipQuestion}
          courseMode={courseMode}
        />
      )}
      {!isRecordingAnswer && (
        <AnswerPlayer
          question={questions[currentQuestionIndex]}
          answerBlobUrl={answerBlobUrl}
          mimeType={mimeType}
          onAnswerAgain={onAnswerAgain}
          onNextQuestion={onNextQuestion}
        />
      )}
    </>
  )
}

export default ExerciseStep
