const detectSupportedBrowser = () => {
  console.log('navigator.mediaDevices supported: ', !!navigator?.mediaDevices)
  console.log('Blob supported: ', !!Blob)
  console.log('MediaRecorder supported: ', !!MediaRecorder)

  if (!navigator?.mediaDevices || !Blob || !MediaRecorder) {
    return false
  } else {
    return true
  }
}

export default detectSupportedBrowser
