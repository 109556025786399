export default [
  { quote: 'Скажи что-нибудь. Я хочу тебя увидеть.', author: 'Сократ' },
  { quote: 'Говорить не думая — все равно что стрелять не целясь.', author: 'Мигель Сервантес' },
  { quote: 'Чем проще человек выражается, тем легче его понимают.', author: 'Джеймс Фенимор Купер' },
  { quote: 'Достаточно, чтобы слова выражали смысл.', author: 'Конфуций' },
  { quote: 'Сила речи состоит в умении выразить многое в немногих словах.', author: 'Плутарх' },
  { quote: 'Нет правды в человеке, который не в состоянии контролировать свой язык.', author: 'Махатма Ганди' },
  { quote: 'Ясность - главное достоинство речи.', author: 'Аристотель' },
  { quote: 'Когда сказать нечего, всегда говорят плохо.', author: 'Вольтер' },
  {
    quote:
      'Хочешь быть умным, научись разумно спрашивать, внимательно слушать, спокойно отвечать и переставать говорить, когда нечего больше сказать.',
    author: 'Иоганн Каспар Лафатер',
  },
  {
    quote:
      'Если язык человека вял, тяжел, сбивчив, бессилен, неопределен, необразован, то таков, наверное, и ум этого человека, ибо мыслит он только при посредстве языка.',
    author: 'Иоганн Готфрид Гердер',
  },
  { quote: 'Читая авторов, которые хорошо пишут, привыкают хорошо говорить.', author: 'Вольтер' },
  { quote: 'Достоинство речи - быть ясной и не быть низкой.', author: 'Аристотель' },
  { quote: 'Язык голову бережет.', author: 'Бальтасар Грасиан' },
  { quote: 'Речь дается многим, но мудрость редким.', author: 'Марк Порций Катон Старший' },
  {
    quote: 'Мы должны стремиться не к тому, чтобы нас всякий понимал, а к тому, чтобы нас нельзя было не понять.',
    author: 'Вергилий Публий',
  },
  {
    quote:
      'Не знаю ничего прекраснее, чем умение силою слова приковывать к себе толпу слушателей, привлекать их расположение, направлять их волю куда хочешь и отвращать ее откуда хочешь.',
    author: 'Марк Туллий Цицерон',
  },
  { quote: 'Лучше ничего не сказать, чем сказать ничего.', author: 'Анатолий Кони' },
  { quote: 'Порядочность обнаруживается в речах, но куда вернее — в делах.', author: 'Бальтасар Грасиан' },
  { quote: 'И самая блестящая речь надоедает, если ее затянуть.', author: 'Паскаль Блез' },
  { quote: 'Кто ясно мыслит, тот ясно и излагает.', author: 'Никола Буало-Депрео' },
  { quote: 'Язык — одежда мыслей.', author: 'Сэмюэл Джонсон' },
  {
    quote:
      'Слишком старательные поиски слов часто портят всю речь. Лучшие слова - это те, которые являются сами собой; они кажутся подсказанными самою правдой.',
    author: 'Марк Фабий Квинтилиан',
  },
  {
    quote: 'Если хотите высказывать серьезные мысли, отучитесь сперва болтать вздор.',
    author: 'Люк де Клапье Вовенарг',
  },
  {
    quote:
      'Поистине, род человеческий готов лишиться чего угодно, но не голоса и речи; в этом одном уже неизмеримое его богатство.',
    author: 'Дион Хрисостом',
  },
  {
    quote:
      'Если от грязных ног, идущих по глине и кучам мусора, вред невелик, то от невежественного языка немалый урон бывает слушателям.',
    author: 'Дион Хрисостом',
  },
  { quote: 'Если ты дважды подумаешь, прежде чем один раз сказать, ты скажешь вдвое лучше.', author: 'Томас Пейн' },
  {
    quote:
      'Ничто для нас столь обыкновенно, ничто столь просто кажется, как речь наша, но в самом существе ничто столь удивительно есть, столь чудесно, как наша речь.',
    author: 'Александр Радищев',
  },
  {
    quote:
      'Несколько примеров, приведенных в немногих словах и на своем месте, придают мыслям больше блеску, больше весу и авторитетности; но излишнее обилие примеров и излишек подробностей всегда ослабляют речь.',
    author: 'Люк де Клапье Вовенарг',
  },
  {
    quote: 'Главное в ораторском искусстве состоит в том, чтобы не дать приметить искусства.',
    author: 'Марк Фабий Квинтилиан',
  },
  { quote: 'Надежней конь без узды, чем речь без связи.', author: 'Теофраст' },
  { quote: 'Чистота речи совершенствуется посредством чтения ораторов и поэтов.', author: 'Марк Туллий Цицерон' },
  {
    quote:
      'Речь нуждается в захватывающем начале и убедительной концовке. Задачей хорошего оратора является максимальное сближение этих двух вещей.',
    author: 'Гилберт Кит Честертон',
  },
  { quote: 'В устную речь можно вложить еще более тонкий смысл, чем в письменную.', author: 'Жан де Лабрюйер' },
  { quote: 'Как в жизни, так и в речи нет ничего труднее, как видеть, что уместно.', author: 'Марк Туллий Цицерон' },
  {
    quote:
      'Красиво сказанная речь о прекрасных деяниях остается в памяти слушающих, к чести и славе тех, кто эти дела совершил.',
    author: 'Платон',
  },
]
