import React from 'react'
import { Button } from '../../../design'
import { navigate } from 'gatsby'
import './IntroStep.scss'

const IntroStep = ({ introText, onStepComplete }) => {
  const getContent = () => {
    return (
      <>
        <h3 className="greeting">{introText}</h3>
        <Button className="start-button" size="large" onClick={() => onStepComplete()}>
          Поехали!
        </Button>
        <Button type="link" onClick={() => navigate('/categories')}>
          ← Назад к категориям вопросов
        </Button>
      </>
    )
  }

  return (
    <div className="section-intro">
      <div className="section-inner section-inner--m vertical-indent--xs">{getContent()}</div>
    </div>
  )
}

export default IntroStep
