import React, { useState, useEffect } from 'react'

const useMimeType = () => {
  const [mimeType, setMimeType] = useState()

  useEffect(() => {
    const VIDEO_TYPES = ['webm', 'ogg', 'mp4', 'x-matroska']
    const VIDEO_CODECS = ['vp9', 'vp9.0', 'vp8', 'vp8.0', 'avc1', 'av1', 'h265', 'h.265', 'h264', 'h.264', 'opus']

    let bestVariation

    for (const videoType of VIDEO_TYPES) {
      const type = `video/${videoType}`

      if (bestVariation) break

      for (const codec of VIDEO_CODECS) {
        const variations = [
          `${type};codecs=${codec}`,
          `${type};codecs:${codec}`,
          `${type};codecs=${codec.toUpperCase()}`,
          `${type};codecs:${codec.toUpperCase()}`,
          `${type}`,
        ]

        const supportedVariation = variations.find(variation => {
          if (MediaRecorder.isTypeSupported(variation)) {
            return variation
          }
        })

        if (supportedVariation) {
          bestVariation = supportedVariation
          break
        }
      }
    }

    if (bestVariation) {
      console.log('best mime type: ', bestVariation)
      setMimeType(bestVariation)
    }
  }, [])

  return mimeType
}

export default useMimeType
