import React, { useEffect, useLayoutEffect, useState } from 'react'
import { navigate } from 'gatsby'
import { useSpring, animated } from 'react-spring'
import queryString from 'query-string'
import classNames from 'classnames'
import { Layout } from '../components'
import { Button } from '../design'
import useMimeType from '../hooks/useMimeType'
import { getHasMediaPermissions } from '../utils/storage'
import detectDevices from '../utils/detectDevices'
import { springFadeInConfig } from '../utils/animation'
import shuffle from '../utils/shuffle'
import detectSupportedBrowser from '../utils/detectSupportedBrowser'
import { BlockerInfo, PermissionsStep, IntroStep, ExerciseStep, ResultStep } from '../components/simulator'
import questionSets from '../fixtures/questions'
import initialQuotes from '../fixtures/quotes'
import './simulator.scss'

const QUESTIONS_PER_INTERVIEW = 5

const Simulator = ({ location }) => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0)
  const [hasPermissions, setHasPermissions] = useState()
  const [isBrowserSupported, setIsBrowserSupported] = useState()
  const [hasCamera, setHasCamera] = useState()
  const [hasMicrophone, setHasMicrophone] = useState()
  const [introFinished, setIntroFinished] = useState(false)
  const [introText, setIntroText] = useState()
  const [questions, setQuestions] = useState([])
  const [quotes, setQuotes] = useState([])
  const [category, setCategory] = useState()
  const [answeredQuestions, setAnsweredQuestions] = useState([])
  const [videoAnswerURLs, setVideoAnswerURLs] = useState([])
  const [answerDurations, setAnswerDurations] = useState([])
  const [skippedQuestions, setSkippedQuestions] = useState([])
  const [isForceFinishInterview, setIsForceFinishInterview] = useState(false)
  const [finishedQuestionsCounter, setFinishedQuestionsCounter] = useState(0)
  const [isReviewingAnswer, setIsReviewingAnswer] = useState(false)
  const springProps = useSpring(springFadeInConfig)
  const mimeType = useMimeType()

  useEffect(() => {
    // clean memory from blob objects
    return () => cleanMemory()
  }, [])

  useLayoutEffect(() => {
    const checkDevices = async () => {
      const devices = await detectDevices()
      setHasCamera(devices.hasCamera)
      setHasMicrophone(devices.hasMicrophone)
    }

    checkDevices()

    const hasMediaPermissions = getHasMediaPermissions()
    setIsBrowserSupported(detectSupportedBrowser())
    setHasPermissions(hasMediaPermissions)
  }, [])

  useLayoutEffect(() => {
    const { category } = queryString.parse(location.hash)
    const questionSet = questionSets[category]

    let questionsToAsk
    if (questionSet) {
      questionsToAsk = questionSet.randomOrder ? shuffle(questionSet.questions) : questionSet.questions
    }

    setCategory(category)
    setQuestions(questionsToAsk)
    setIntroText(questionSet?.introText)
    setQuotes(initialQuotes)
  }, [location.search])

  const cleanMemory = () => {
    videoAnswerURLs.forEach(url => URL.revokeObjectURL(url))
  }

  const onIntroStepComplete = () => {
    setIntroFinished(true)
  }

  const onAddAnswer = async (question, blob, duration) => {
    const url = URL.createObjectURL(blob)
    setAnsweredQuestions([...answeredQuestions, question])
    setVideoAnswerURLs([...videoAnswerURLs, url])
    setAnswerDurations([...answerDurations, duration])
    setIsReviewingAnswer(true)
  }

  const onRemoveLastAnswer = () => {
    const lastAnswerURL = videoAnswerURLs[videoAnswerURLs.length - 1]
    URL.revokeObjectURL(lastAnswerURL)

    setAnsweredQuestions(answeredQuestions.slice(0, answeredQuestions.length - 1))
    setVideoAnswerURLs(videoAnswerURLs.slice(0, answeredQuestions.length - 1))
    setAnswerDurations(answerDurations.slice(0, answeredQuestions.length - 1))
    setIsReviewingAnswer(false)
  }

  const onQuestionComplete = () => {
    setIsReviewingAnswer(false)
    setFinishedQuestionsCounter(prev => prev + 1)
  }

  const onSkipQuestion = question => {
    setSkippedQuestions([...skippedQuestions, question])
  }

  const forceFinishInterview = () => {
    setIsForceFinishInterview(true)
  }

  const onContinue = () => {
    cleanMemory()
    setAnsweredQuestions([])
    setVideoAnswerURLs([])
    setAnswerDurations([])
    setSkippedQuestions([])
    setIsForceFinishInterview(false)
    setFinishedQuestionsCounter(0)
  }

  const getStep = () => {
    // prevent steps blinking when the data is not read from the local storage yet
    if (hasPermissions === undefined || hasMicrophone === undefined || hasCamera === undefined) return null

    if (!category || !introText || !questions || !quotes) {
      return (
        <div className="section-inner section-cannot-find-questions">
          <h2>Не удалось найти вопросы выбранной категории</h2>
          <Button size="large" onClick={() => navigate('/categories')}>
            Вернуться к списку категорий
          </Button>
        </div>
      )
    }

    if (!hasMicrophone || !hasCamera || !isBrowserSupported) {
      return (
        <animated.div style={springProps}>
          <BlockerInfo noCamera={!hasCamera} noMicrophone={!hasMicrophone} unsupportedBrowser={!isBrowserSupported} />
        </animated.div>
      )
    }

    if (!hasPermissions) {
      return <PermissionsStep onStepComplete={() => setHasPermissions(true)} />
    }

    if (hasPermissions && !introFinished) {
      return (
        <animated.div style={springProps}>
          <IntroStep introText={introText} onStepComplete={onIntroStepComplete} />
        </animated.div>
      )
    }

    if (finishedQuestionsCounter === QUESTIONS_PER_INTERVIEW || isForceFinishInterview) {
      return (
        <ResultStep
          answeredQuestions={answeredQuestions}
          skippedQuestions={skippedQuestions}
          videoAnswerURLs={videoAnswerURLs}
          answerDurations={answerDurations}
          mimeType={mimeType}
          quote={quotes[Math.floor(Math.random() * quotes.length)]}
          onContinue={onContinue}
          noMoreQuestions={currentQuestionIndex >= questions.length}
        />
      )
    }

    return (
      <ExerciseStep
        questions={questions}
        mimeType={mimeType}
        onAddAnswer={onAddAnswer}
        onSkipQuestion={onSkipQuestion}
        onNoMoreQuestions={forceFinishInterview}
        onRemoveLastAnswer={onRemoveLastAnswer}
        onQuestionComplete={onQuestionComplete}
        currentQuestionIndex={currentQuestionIndex}
        setCurrentQuestionIndex={setCurrentQuestionIndex}
      />
    )
  }

  const renderControlPanel = () => {
    if (!introFinished || finishedQuestionsCounter === QUESTIONS_PER_INTERVIEW || isForceFinishInterview) return

    const answeredPercentage = Math.round((finishedQuestionsCounter / QUESTIONS_PER_INTERVIEW) * 100)
    const reviewingPercentage =
      Math.round(((finishedQuestionsCounter + 1) / QUESTIONS_PER_INTERVIEW) * 100) - answeredPercentage

    const greenLineStaticClass = classNames('green-line--static', { 'green-line--static-squared': isReviewingAnswer })
    const greenLineAnimatedClass = classNames('green-line--animated', {
      'green-line--animated-squared': answeredPercentage > 0,
    })

    return (
      <div className="section-control-panel section-outer--dark-gray">
        <div className="section-inner vertical-indent--xxs">
          <div className="progress-bar">
            <div className={greenLineStaticClass} style={{ width: `${answeredPercentage}%` }} />
            {isReviewingAnswer && (
              <div
                className={greenLineAnimatedClass}
                style={{ width: `${reviewingPercentage}%`, left: `${answeredPercentage}%` }}
              ></div>
            )}
            <div className="progress-text">{`Прогресс интервью ${answeredPercentage}%`}</div>
          </div>
          <div>
            <Button className="finish-button" type="inverted" onClick={forceFinishInterview}>
              Закончить интервью
            </Button>
          </div>
        </div>
      </div>
    )
  }

  return (
    <Layout
      renderBottomContent={renderControlPanel}
      hideFooter={introFinished && finishedQuestionsCounter !== QUESTIONS_PER_INTERVIEW && !isForceFinishInterview}
      centerContentVertically
    >
      {getStep()}
    </Layout>
  )
}

export default Simulator
