import React from 'react'
import { useSpring, animated } from 'react-spring'
import { springFadeInConfig } from '../../../utils/animation'
import { Button } from '../../../design'

const InfoStep = ({ onStepComplete }) => {
  const springProps = useSpring(springFadeInConfig)

  return (
    <animated.div style={springProps}>
      <div className="section-permissions__info">
        <h2 className="title">Для начала работы нам нужен доступ к Вашей камере и микрофону</h2>
        <p className="description">
          Обратите внимание, что мы не храним записи Ваших ответов. Запись доступна только в браузере вашего компьютера
          и будет автоматически удалена при переходе к следующему вопросу или при закрытии страницы браузера.
          Тренируйтесь спокойно :)
        </p>
        <Button size="large" onClick={onStepComplete}>
          Понятно!
        </Button>
      </div>
    </animated.div>
  )
}

export default InfoStep
