const detectDevices = async callback => {
  let md = navigator.mediaDevices
  if (!md || !md.enumerateDevices) return callback(false)

  const devices = await md.enumerateDevices()
  console.log(JSON.stringify(devices))

  const hasCamera = devices.some(device => 'videoinput' === device.kind)
  const hasMicrophone = devices.some(device => 'audioinput' === device.kind)

  return {
    hasCamera,
    hasMicrophone,
  }
}

export default detectDevices
